<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--header pb-3">
        <h1>My Watchlists</h1>
        <span class="flex align-center flex-wrap">
          <router-link :to="{name: 'userWatchlist'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isUserWatchlist, 'btn__outlined': !isUserWatchlist }">Users</button>
          </router-link>
          <router-link :to="{name: 'orderWatchlist'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isOrderWatchlist, 'btn__outlined': !isOrderWatchlist }">Orders</button>
          </router-link>
          <router-link :to="{name: 'groupWatchlist'}">
            <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': isGroupWatchlist, 'btn__outlined': !isGroupWatchlist }">Groups</button>
          </router-link>
          <button class="btn btn__outlined btn__small" @click="goBack()"><i class="fas fa-arrow-left"></i></button>
        </span>
        
      </div>
      <router-view :key="$route.params.id" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'

export default {
  name: 'watchlistHome',
  computed: {
    ...mapState(['currentUser', 'userProfile']),
    isUserWatchlist() {
      return this.$route.name == 'userWatchlist';
    },
    isOrderWatchlist() {
      return this.$route.name == 'orderWatchlist';
    },
    isGroupWatchlist() {
      return this.$route.name == 'groupWatchlist';
    },
  },
  methods: {
    goBack() {
      router.go(-1)
    },
  }
}
</script>